


































import {Vue, Component} from "vue-property-decorator";
import {ServiceVersion} from "@/request/plat/ServiceVersion";

@Component({
    name: 'plat_serviceVersion'
})
export default class plat_service_version extends Vue {
    modal: any = ServiceVersion;
    formData: any = {
        funcDesc: '',
        version: ''
    };
    formRules: any = {
        funcDesc: {required: true, trigger: 'blur'},
        version: {required: true},
    };
    columns: Array<any> = [
        {
            title: '功能描述',
            key: 'funcDesc',
            minWidth: 140
        },
        {
            title: '版本',
            key: 'version',
            minWidth: 120
        },
        {
            title: '创建人',
            key: 'createName',
            minWidth: 120
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {

    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
}
